<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-07-05 09:52:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-19 14:16:41
 * @FilePath: \official_website\src\views\science\componets\Decipher.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 文献解读 -->
<template>
  <div id="decipher">
    <div class="decipher-list">
      <div class="list-item" v-for="item in decipherList" :key="item.id">
        <div class="left">
          <div class="tiems">
            <div class="day">{{ item.day }}</div>
            <div class="year-month">{{ item.year }} - {{ item.month }}</div>
          </div>
          <div class="list-conent wow animate__animated animate__fadeInUp">
            <div class="title" @click="tapArticle(item.id)">
              {{ item.title }}
            </div>
            <div class="subtitle">
              {{ item.summary }}
            </div>
          </div>
        </div>
        <!-- :style="{'backgroundImage': `url(${item.titleImage}`}" -->
        <div class="right">
          <img :src="item.titleImage" alt="" />
        </div>
      </div>
    </div>
    <div class="pagination-box">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="count"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      decipherList: [], // 分页新闻列表
      count: 0, // 新闻列表数量
      currentPage: 1, // 当前页码
    };
  },
  created() {
    this.getDecipherList();
    this.getDecipherCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    // 分页获取文献列表
    getDecipherList(page = 1) {
      api
        .getDecipherByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });
            this.decipherList = result;
            console.log(this.decipherList);
            // this.$nextTick(() => {
            //   new this.$wow.WOW({
            //     boxClass: "wow", // 动画元件css类（默认为wow）
            //     animateClass: "animate__animated", //动画css类（默认为animated）
            //     offset: 0, //到元素距离触发动画（当默认为0）
            //     mobile: true, //在移动设备上触发动画（默认为true）
            //     live: true, //对异步加载的内容进行操作（默认为true）
            //   }).init();
            // });
          }
        })
        .catch((error) => {});
    },
    // 获取文献总条数
    getDecipherCounts() {
      api
        .getDecipherCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDecipherList(val);
    },
    tapArticle(id) {
      this.$router.push({
        path: `/decipherDetail/${id}`,
      });
    },
  },
};
</script>
    
<style lang="scss">
#decipher {
  .decipher-list {
    padding: 0 5%;
    margin-bottom: 2rem;
    .list-item {
      padding: 3.125rem 0;
      border-bottom: 1px solid #ddd;
      display: flex;
      .left {
        width: 60%;
        display: flex;
        .tiems {
          width: 20%;
          .day {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            font-family: "SourceHanSansSC-Medium";
          }
          .year-month {
            font-size: 1.2rem;
            color: #666666;
            font-family: "OPlusSans3-Regular";
          }
        }
        .list-conent {
          width: 80%;
          .title {
            width: 60%;
            font-size: 1.4rem;
            margin-bottom: 1rem;
            font-family: "SourceHanSansSC-Medium";
            margin-bottom: 2.5rem;
            cursor: pointer;
          }
          .subtitle {
            width: 70%;
            font-size: 1rem;
            color: #666666;
            font-family: "OPlusSans3-Regular";
          }
        }
      }
      .right {
        width: 40%;
        height: 20rem;
        // background-size: cover;
        // background-position: center center;
        // background-repeat: no-repeat;
        img {
          width: 100%;
          height: 100%;
          border: 0;
          vertical-align: middle;
          display: inline-block;
          object-fit: cover;
          &:hover {
            animation-name: scaleDraw; /*关键帧名称*/
            animation-timing-function: ease; /*动画的速度曲线*/
            animation-duration: 2s; /*动画所花费的时间*/
          }
        }
      }
      @keyframes scaleDraw {
        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    .el-pager li,
    .el-pagination button {
      height: 2.25rem;
      line-height: 2.25rem;
      min-width: 2.25rem;
      font-size: 0.95rem;
    }
    .el-pagination .btn-prev .el-icon,
    .el-pagination .btn-next .el-icon {
      font-size: 0.95rem;
    }
  }
}
</style>